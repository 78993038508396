import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomMultiSelectSharedService {
  public subjectMultiSelectValue = new BehaviorSubject(null);
  constructor() { }

  setSubjectMultiSelectValue(dateObj: any) {
    this.subjectMultiSelectValue.next(dateObj);
  }

  getSubjectMultiSelectValue(): Observable<any> {
    return this.subjectMultiSelectValue.asObservable();
  }
}
