import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
@Injectable()
export class UrlInterceptorService {

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.checkForSsoCallback(event.urlAfterRedirects);
      }
    });
  }

  private checkForSsoCallback(url: string): void {
    // Check if the URL matches the specific route
    if (url.startsWith('/sso-callback')) {
      // Perform actions specific to this route
      console.log('SSO Callback route detected:', url);

      // Example: Parse query parameters or perform an action
      const queryIndex = url.indexOf('?');
      if (queryIndex !== -1) {
        const queryParams = url.substring(queryIndex + 1);
        const urlParams = new URLSearchParams(queryParams);
        const code = urlParams.get('code');
        const state = urlParams.get('state');

        // Now you can handle the parameters or perform other logic
        console.log('Authorization code:', code);
        alert(code)
        console.log('State:', state);
      }
    }

}
}