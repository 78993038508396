import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/core/service/language.service';
import { ReplaySubject, pairwise, takeUntil } from 'rxjs';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { LookupsService } from 'src/app/shared/services/lookups.service';
import { LookUpElement } from 'src/app/shared/models/common-data.model';
@Component({
  selector: 'app-month-select',
  templateUrl: './month-select.component.html',
  styleUrls: ['./month-select.component.sass']
})
export class MonthSelectComponent {
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  monthSelectForm: FormGroup;
  monthSearch: string = '';
  monthLookup: LookUpElement[] = [];
  constructor(
    private fb: FormBuilder,
    private translateService :TranslateService,
    private languageService : LanguageService,
    public helperService: HelpersService,
    public lookupsService: LookupsService
  ) {}

  ngOnInit(): void {
    this.createMonthSelectForm();
  }

  createMonthSelectForm() {
    this.monthSelectForm = this.fb.group({
      Month: [''],
    });
  }
}
