<ng-template #template>
    <div class="rest-icon">
      <a style="cursor: pointer;" (click)="cancelForm()"> <span class="text-end"><i
            class="material-icons-outlined filter-btn-icon icon-font-size">close</i></span></a>
    </div>
    <h6 class="modal-title pull-left filter-modal-title">{{'NOTIFICATION_MENU.NOTIFICATION_SETTING' | translate}}</h6>
    <div class="modal-body p-2">
      <div class="col-12 filter-container">
        <form [formGroup]="plateForm" class="col-12 filter-form">
          <div class="row">
              <div class="col-md-12">
                  <p>{{'NOTIFICATION_MENU.SETUP' | translate}}</p>
              </div>
          </div>
          <div class="row">
              <div class="col-md-12 pt-2 pb-2">
                  <mat-slide-toggle formControlName="isActive">&nbsp;&nbsp;&nbsp;{{'NOTIFICATION_MENU.DO_NOT_DISTURB' | translate}}</mat-slide-toggle>
              </div>
              <hr>
              <div class="col-md-12 pt-2 pb-2">
                  <mat-slide-toggle formControlName="isActive">&nbsp;&nbsp;&nbsp;{{'NOTIFICATION_MENU.WHEN_I_AM_ACTIVE_STOP_SENDING_NOTIFICATIONS' | translate}}</mat-slide-toggle>
              </div>
              <hr>
              <div class="col-md-12 pt-2 pb-2">
                  <mat-slide-toggle formControlName="isActive">&nbsp;&nbsp;&nbsp;{{'NOTIFICATION_MENU.DISABLE_NOTIFICATION_FROM' | translate}}</mat-slide-toggle>
              </div>
              <hr>
          </div>
        </form>
      </div>
    </div>
    <div class="filter-close-div">
      <div class="example-button-row">
        <button
          mat-stroked-button
          color="primary"
          class="btn-border outline-info-btn btn-text-size"
          (click)="cancelForm()"
        >
        {{'MASTER_MENU.CANCEL' | translate}}<mat-icon>cancel</mat-icon>
        </button>
        <button mat-flat-button color="primary" class="flat-info-btn btn-text-size">
          {{'MASTER_MENU.SAVE' | translate}}<mat-icon>save</mat-icon>
        </button>
      </div>
    </div>
  </ng-template>
  