<ng-template #template>
  <h5 class="modal-title pull-left"> {{'ADMINISTRATION_MENU.RESET_PASSWORD' | translate}}</h5>
  <div class="modal-body p-2">
    <form [formGroup]="resetPasswordForm">
      <div class="row">
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{'SIGNUP_MENU.PASSWORD' | translate}}</mat-label>
            <input [type]="hide ? 'password' : 'text'" formControlName="Password" matInput required pattern="\S(.*\S)?">
            <mat-icon matSuffix (click)="hide = !hide">{{hide ?
              'visibility_off' : 'visibility'}}</mat-icon>
              <mat-error *ngIf="resetPasswordForm.get('Password').hasError('pattern')"> {{'MASTER_MENU.WHITESPACE_CANT_CONTAIN_START_AND_END' | translate}}</mat-error>
            <mat-error *ngIf="resetPasswordForm.get('Password').hasError('required')"> {{'SIGNUP_MENU.PASSWORD_IS_REQUIRED' | translate}}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label> {{'SIGNUP_MENU.CONFIRM_PASSWORD' | translate}}</mat-label>
            <input [type]="hideConform ? 'password' : 'text'" formControlName="ConformPassword" matInput required pattern="\S(.*\S)?">
            <mat-icon matSuffix (click)="hideConform = !hideConform">{{hideConform ?
              'visibility_off' : 'visibility'}}</mat-icon>
              <mat-error *ngIf="resetPasswordForm.get('ConformPassword').hasError('pattern')"> {{'MASTER_MENU.WHITESPACE_CANT_CONTAIN_START_AND_END' | translate}}</mat-error>
            <mat-error *ngIf="resetPasswordForm.get('ConformPassword').hasError('required')"> {{'SIGNIN_MENU.CONFORM_PASSWORD_IS_REQUIRED' | translate}}</mat-error>
          </mat-form-field>
          <mat-error class="custom-error" *ngIf="resetPasswordForm.get('ConformPassword').value && resetPasswordForm.get('Password').value != resetPasswordForm.get('ConformPassword').value"> {{'SIGNIN_MENU.CONFIRM_PASSWORD_AND_PASSWORD_NOT_MATCH' | translate}}</mat-error>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button mat-stroked-button color="primary" class="btn-border outline-info-btn btn-text-size" (click)="cancelForm()">
      {{'MASTER_MENU.CANCEL' | translate}}<mat-icon>cancel</mat-icon>
    </button>
    <button mat-flat-button color="primary" class="flat-info-btn btn-text-size" (click)="resetPassword(resetPasswordForm.value)" [disabled]="!resetPasswordForm.valid || resetPasswordForm.get('Password').value != resetPasswordForm.get('ConformPassword').value">
      {{'MASTER_MENU.SAVE' | translate}}<mat-icon>save</mat-icon>
    </button>
  </div>
</ng-template>
