import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { AuthService } from '../service/auth.service';
import jwt_decode from "jwt-decode";
import { User } from '../models/user';
import { Router } from '@angular/router';
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  // private currentUserSubject: BehaviorSubject<User>;
  token: string;
  constructor(private authenticationService: AuthService, private router: Router) {



  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    let currentUser = this.authenticationService?.currentUserValue;


    this.token = currentUser?.token;





    if ((currentUser && currentUser.token) && !request.url.includes("realm")) {
      // console.log(this.isTokenExpired(currentUser?.token), "_Token Expire");
      // console.log(, "refreshtoken_Token Expire");


      if (this.isTokenExpired(currentUser?.token) && !this.isTokenExpired(currentUser?.refresh_token) && !this.router.url.includes('signin')) {


        this.authenticationService.setRefreshToken(currentUser.refresh_token)
        this.refreshToken().subscribe(x => {
          // console.log(x , "   refresh token res");
          this.assignToken(x);

          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${this.token}`,
            },
          });

        });

      }

      if (this.isTokenExpired(currentUser?.token) && this.isTokenExpired(currentUser?.refresh_token)) {
        this.authenticationService.logout().subscribe((res)=>{
          location.reload();

        });
      } else {

        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.token}`,
          },
        });

      }
    }



    return next.handle(request);
  }


  isTokenExpired(token: string): boolean {
    try {
      const decodedToken: any = jwt_decode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      return decodedToken.exp < currentTime;
    } catch (error) {
      // Error decoding token
      return true;
    }
  }

  assignToken(res) {

    const token_data: any = jwt_decode(res.access_token);


    var roles = token_data?.realm_access?.roles ?? [];

    var user: User = {
      firstName: token_data.given_name,
      lastName: token_data.family_name,
      img: "",
      id: token_data.sub,
      password: "",
      role: this.authenticationService.currentUserValue.role,
      username: token_data.preferred_username,
      name: token_data.name,
      token: res.access_token,
      email: token_data.email,
      refresh_token: res.refresh_token
    }




    localStorage.setItem('currentUser', JSON.stringify(user));

    this.authenticationService.currentUserSubject.next(user);

  }

  refreshToken(): Observable<any> {

    return this.authenticationService.getRefreshToken();

  }
}
