import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Observable, Subject } from 'rxjs';
import { NotificationModel } from 'src/app/layout/header/header.component';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  // hubUrl: string;
  // connection: any;
  private hubConnection: signalR.HubConnection;
  private messageSubject: Subject<any> = new Subject<any>();
  constructor(private authService : AuthService) {
    // this.hubUrl = 'https://demoapi.tmdone.com/signalr/hubs';
  }



  public connect(): void {

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.signalRUrl)
      .build();
    this.hubConnection.on('ReceiveNotification', (message: NotificationModel) => {
      console.log(message);
      this.messageSubject.next(message);
    });

    this.hubConnection.start()
      .then(() => {
        this.joinGroup("USER_"+this.authService.currentUserValue.id+"_COMPANY_"+this.authService.getCurrentCompanyValue.id)
        this.joinGroup("USER_"+this.authService.currentUserValue.id)
        console.log('Hub connection started');
      })
      .catch((error) => {
        console.log('Error starting hub connection:', error);
      });
  }
  private joinGroup(groupName: string): void {

    console.log(groupName,"groupName");

    if (this.hubConnection) {
      this.hubConnection.invoke('AddToGroup', groupName)
        .then(() => {
          console.log(`Joined group: ${groupName}`);
        })
        .catch((error) => {
          console.log('Error joining group:', error);
        });
    }
  }
  public disconnect(): void {
    if (this.hubConnection) {
      this.hubConnection.stop()
        .then(() => {
          console.log('Hub connection stopped');
        })
        .catch((error) => {
          console.log('Error stopping hub connection:', error);
        });
    }
  }

  public sendMessage(message: string): void {
    if (this.hubConnection) {
      this.hubConnection.invoke('SendNotification', message)
        .then(() => {
          console.log('Message sent:', message);
        })
        .catch((error) => {
          console.log('Error sending message:', error);
        });
    }
  }

  public getMessageObservable(): Observable<NotificationModel> {
    return this.messageSubject.asObservable();
  }
}
