<ng-template #template>
  <div class="rest-icon">
    <a style="cursor: pointer;" (click)="resetFilter()"> <span class="text-end"><i
          class="material-icons-outlined filter-btn-icon icon-font-size">restart_alt</i></span></a>
  </div>
  <h6 class="modal-title pull-left filter-modal-title">{{'FILTER_MENU.COLUMN_SELECTIONS' | translate}}</h6>
  <!-- <div class="modal-header">
         <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">×</span>
         </button>
      </div> -->
  <div class="modal-body p-2">

    <div class="col-12 mt-3 filter-container">
      <form [formGroup]="filterForm" class="filter-form">
        <mat-form-field class=" search-input mt-2 full-width-field" appearance="">
          <mat-label>{{'FILTER_MENU.SEARCH' | translate}}</mat-label>
          <input matInput (input)="searchChange($event)" />
          <mat-icon matSuffix class="face-icon">search</mat-icon>
        </mat-form-field>
        <!-- <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" formControlName="columns">
            <mat-radio-button class="example-radio-button" *ngFor="let item of coloumnsData" [value]="item.data">
              {{item.title}}
            </mat-radio-button>
          </mat-radio-group> -->
        <mat-list role="list" class="mat-list"  (cdkDropListDropped)="drop($event)" cdkDropList >
          <mat-list-item role="listitem" class="mat-list-item" *ngFor="let item of coloumnsData" formArrayName="columns"  cdkDrag>
            <div class="w-100" *ngIf="!item.isRejectColumSelection">
              <mat-icon class="drag-icon">drag_indicator</mat-icon>
              <mat-slide-toggle class="example-margin" [value]="item.data" id="{{item.data}}" (change)="onCheckboxChange($event)" [checked]="this.filterForm?.get('columns')?.value?.includes(item.data)" *ngIf="item.allowFilter">
                {{item?.translate | translate}}
              </mat-slide-toggle>
            </div>
          </mat-list-item>

        </mat-list>



      </form>

    </div>
  </div>
  <hr/>
  <div class="filter-close-div">
    <!-- <button mat-flat-button color="primary" class="flat-info-btn btn-text-size" (click)="applyFilter()">
      Apply Filter<mat-icon>add</mat-icon>
    </button>&nbsp;&nbsp; -->
    <button type="button" mat-stroked-button color="primary" class="btn-border outline-info-btn btn-text-size" (click)="modalRef.hide()">{{'FILTER_MENU.CLOSE' | translate}}</button>&nbsp;&nbsp;
  </div>
</ng-template>
