<ng-template #template>
  <h5 class="modal-title"><b>{{getHeader() | translate}}</b>
    <mat-icon class="pull-right modal-close-icon" (click)="cancelStepper()">close</mat-icon>
  </h5>
  <mat-stepper linear #stepper>
    <mat-step [stepControl]="firstFormGroup">
      <form [formGroup]="firstFormGroup">
        <ng-template matStepLabel>{{"MASTER_MENU.STEP" | translate}}</ng-template>
        <app-excel-upload (change)="onExcelFileSelected($event)"></app-excel-upload>

        <div class="download-btn-container">
          <button mat-flat-button class="flat-info-btn btn-text-size" color="primary" [matMenuTriggerFor]="menu1">
            <mat-icon>expand_more</mat-icon> {{"MASTER_MENU.SELECT_AND_DOWNLOAD_SAMPLE_FILE" | translate}}
          </button>
          <mat-menu class="tableInnerMenu" #menu1="matMenu">
            <button class="menu-custom-item" mat-menu-item (click)="downloadInquiryTemplate(true)"><span
                class="menu-custom-span"><mat-icon>download</mat-icon>&nbsp;{{"MASTER_MENU.DOWNLOAD_EMPTY_TEMPLATE" | translate}}</span></button>
            <button class="menu-custom-item" mat-menu-item (click)="downloadInquiryTemplate(false)"><span
                class="menu-custom-span"><mat-icon>download</mat-icon>&nbsp;{{"MASTER_MENU.DOWNLOAD_TEMPLATE_WITH_EXISTING_DATA" | translate}}
              </span></button>
          </mat-menu>
        </div>
        <div class="d-flex flex-row-reverse">
          <button [disabled]="!isExcelFileSelected" class="flat-info-btn btn-text-size mt-3" mat-flat-button
            color="primary" matStepperNext>
            {{"MASTER_MENU.NEXT" | translate}} <mat-icon>navigate_next</mat-icon>
          </button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="secondFormGroup">
      <form class="second-container" [formGroup]="secondFormGroup">
        <ng-template matStepLabel>{{"MASTER_MENU.STEP" | translate}}</ng-template>

        <mat-label class="header-label pd-3">{{"MASTER_MENU.WHAT_DO_YOU_WANT_TO_DO_WITH_THE_RECORDS_IN_THE_FILE" | translate}}</mat-label><br />

        <mat-radio-group formControlName="secondCtrl" [(ngModel)]="selectedRadioButtonValue"
          aria-label="Select an option">
          <mat-radio-button class="mt-6" value="0">{{"MASTER_MENU.ADD_AS_NEW_RECORDS" | translate}}</mat-radio-button>
          <mat-radio-button class="mt-6" value="1">{{"MASTER_MENU.UPDATE_EXISTING_RECORDS_ONLY" | translate}}</mat-radio-button>
          <mat-radio-button class="mt-6" value="2">{{"MASTER_MENU.BOTH" | translate}}</mat-radio-button>
        </mat-radio-group>
        <div class="d-flex flex-row-reverse">
          <button class="flat-info-btn btn-text-size mt-3" [disabled]="!selectedRadioButtonValue"
            (click)="excelUpload()" matStepperNext mat-flat-button color="primary">
            {{"MASTER_MENU.SAVE" | translate}}<mat-icon>save</mat-icon>
          </button>
          &nbsp;&nbsp;&nbsp;
          <button class="btn-border outline-info-btn btn-text-size mt-3" mat-stroked-button color="primary"
            matStepperPrevious>
            {{"MASTER_MENU.BACK" | translate}}<mat-icon>chevron_left</mat-icon>
          </button>
        </div>
      </form>
    </mat-step>

    <mat-step class="third-container">
      <ng-template matStepLabel>{{"MASTER_MENU.DONE" | translate}}</ng-template>
      <app-loader *ngIf="isUploading"></app-loader>
      <div *ngIf="!isUploading" class="text-center mt-3">
        <mat-icon *ngIf="excelUploadRes?.isSuccessed" class="text-success">check_circle</mat-icon>
        <h4 *ngIf="excelUploadRes?.isSuccessed" class="text-success">
          <!-- Excel Uploaded Successfully -->
          {{excelUploadRes?.message}}
        </h4>
        <mat-icon *ngIf="!excelUploadRes?.isSuccessed" class="text-danger">error</mat-icon>
        <h4 *ngIf="!excelUploadRes?.isSuccessed" class="text-danger">
          <!-- Excel Upload Contents Errors -->
          {{excelUploadRes?.message}}
        </h4>
        <a *ngIf="excelUploadRes?.excelUrl" [href]="excelUploadRes?.excelUrl">{{"MASTER_MENU.PLEASE_DOWNLOAD_THE_RESPONSE_HERE" | translate}}</a>
      </div>
      <div>
      </div>
      <div class="d-flex flex-row-reverse">
        <button class="btn-border outline-info-btn btn-text-size mt-3" mat-stroked-button color="primary"
          (click)="cancelStepper()">
          Cancel<mat-icon>cancel</mat-icon>
        </button>
      </div>
    </mat-step>
  </mat-stepper>
</ng-template>
