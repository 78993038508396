import { AuthService } from '../service/auth.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { Toast } from 'src/app/shared/data/constants';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService,private helperService : HelpersService, private translateService: TranslateService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {


    return next.handle(request).pipe(
      catchError((err) => {
        console.log(err);
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.authenticationService.logout();
          location.reload();
        }

        if(err.status === 400) {
          if(err.error!=null) {
            if (err.error?.error_description) {
              Toast.fire({
                icon:'error',
                title:err.error.error_description,
                timer:2000
              })
            } else if(err.error?.message) {
              Toast.fire({
                icon:'error',
                title:err.error?.message,
                timer:2000
              })

            } else {
              Toast.fire({
                icon:'error',
                title:err.error,
                timer:2000
              })

            }
          }
        }

        const error = err.error?.message || err.error?.error_description || err?.statusText ;
        if ( "errors" in err.error) {
          // console.log(err.error.errors);
            this.helperService.showErrorToast(err.error?.title);

          if (err.error.status==400) {

            var validationerrors=err.error.errors;
            var errmsgs:string[]=[];
            Object.keys(validationerrors).forEach(errfield => {

              if (validationerrors[errfield].length>0 ) {
                // Toast.fire({
                //   icon: 'error',
                //   title: errfield +' : '+validationerrors[errfield][0]??''
                // })

                errmsgs.push(validationerrors[errfield][0]??''+'<br/>') ;

                // this.helperService.showErrorToast(errmsg)


              }
            });

            const concatenatedErrors = errmsgs.join("<br>"); // Concatenate errors with line breaks
            Swal.fire({
              icon: 'error',
              title: this.translateService.instant('MAIN_MENU.ERRORS'),
              html: concatenatedErrors, // Use html option to render line breaks
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 5000 // Adjust the timer as needed
            });

          }

        }else{

          // this.helperService.showErrorToast(error)
        }



        return throwError(error);
      })
    );
  }
}
