<mat-form-field class="example-full-width form-input-field">
    <mat-label>{{'MASTER_MENU.YEAR' | translate}}</mat-label>
    <input matInput [max]="maxDate" [matDatepicker]="dp" placeholder="Month and Year" [formControl]="date" readonly>
    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker 
        #dp
        startView="multi-year"
        (yearSelected)="chosenYearHandler($event, dp)"
        panelClass="example-month-picker">
    </mat-datepicker>
</mat-form-field>