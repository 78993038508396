import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from 'src/app/core/service/base-data.service';
@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {
  private apiResetPassword = "api/UserInformation/changePassword"
  constructor(private baseDataService : BaseDataService) { }

  changePassword(userData: any) :Observable<any>{
    return this.baseDataService.makePostCall(this.apiResetPassword,userData)
  }
}
