import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import KcAdminClient from '@keycloak/keycloak-admin-client';
import RoleRepresentation from '@keycloak/keycloak-admin-client/lib/defs/roleRepresentation';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class KeycloakService {
  public kcAdminClient = new KcAdminClient();
  authUrl:string;

  constructor(private http: HttpClient) {
    this.authUrl=environment.authUrl
   }


  async initializeKeyCloak() {
    this.kcAdminClient.setConfig({
      realmName: 'master',
      baseUrl: environment.authUrl,
    });
    // Authorize with username / password
    return await this.kcAdminClient.auth({
      username: 'admin',
      password: 'admin',
      grantType: 'password',
      clientId: 'admin-cli',
      // clientSecret:"HCsTe7Rzpx3iF8UgNgN7gcXMaiVSlS0V",
      // totp: '123456', // optional Time-based One-time Password if OTP is required in authentication flow
    });
  }

  createRole(clientId: string, body: RoleRepresentation) {
    var token = this.getToken();
    let bodyJson = JSON.stringify(body);
    console.log(this.constructUrl(environment.realm + '/clients/' + clientId));

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: 'bearer ' + token,
    });
    return this.http.post(
      this.constructUrl('/' + environment.realm + '/clients/' + clientId),
      bodyJson,
      { headers: headers }
    );
  }

  findRoleByName(roleName: string, token: string) {
    console.log(token);

    //  var token= this.getToken()
    //  let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'bearer ' + token });
    return this.http.get(
      this.constructUrl(
        '/admin/realms/' + environment.realm + '/roles/' + roleName
      ),
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: 'bearer ' + token,
        },
      }
    );
  }
  private async getToken() {
    this.kcAdminClient.setConfig({
      realmName: 'master',
      baseUrl: environment.authUrl,
    });
    // Authorize with username / password
    await this.kcAdminClient.auth({
      username: 'admin',
      password: 'admin',
      grantType: 'password',
      clientId: 'admin-cli',
      // clientSecret:"HCsTe7Rzpx3iF8UgNgN7gcXMaiVSlS0V",
      // totp: '123456', // optional Time-based One-time Password if OTP is required in authentication flow
    });

    return this.kcAdminClient.accessToken;
  }
  private constructUrl(path: string) {
    return environment.authUrl + path;
  }

  login() {
    const clientId = environment.clientId;
    const redirectUri = encodeURIComponent(
      `${window.location.protocol}//${window.location.host}`
    ); // Replace with your actual redirect URI
    const state = encodeURIComponent('random-generated-state'); // A random string to maintain state between request and callback
    const nonce = encodeURIComponent('random-generated-nonce'); // A random string to mitigate replay attacks
    const responseType = 'code';
    const scope = 'openid profile email';
    // const loginUrl = `${this.authUrl}?client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&nonce=${nonce}&response_type=${responseType}&scope=${scope}`;
    const loginUrl = `${this.authUrl}/realms/${environment.realm}/protocol/openid-connect/auth?client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&nonce=${nonce}&response_type=${responseType}&scope=${scope}`;
    return loginUrl;

  }
}
