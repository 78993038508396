import { NgModule } from '@angular/core';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { FilterComponent } from './filter/filter.component';
import { SharedModule } from '../shared.module';
import { FilterSortingComponent } from './filter-modals/filter-sorting/filter-sorting.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import {MatRadioModule} from '@angular/material/radio';
import { FilterColumnComponent } from './filter-modals/filter-column/filter-column.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { FilterGroupComponent } from './filter-modals/filter-group/filter-group.component';
import { LoaderComponent } from './loader/loader.component';
import { Loader } from 'angular-feather/icons';
import { SidemenuModalComponent } from './sidemenu-modal/sidemenu-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { NotificationSettingModalComponent } from './notification-setting-modal/notification-setting-modal.component';
import { CommonDocumentUploadComponent } from './common-document-upload/common-document-upload.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { YearDatePickerComponent } from './date-pickers/year-date-picker/year-date-picker.component';
import { MonthDatePickerComponent } from './date-pickers/month-date-picker/month-date-picker.component';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { FilterParametersComponent } from './filter-modals/filter-parameters/filter-parameters.component';
import { MatExpansionModule} from '@angular/material/expansion';
import {MatStepperModule} from '@angular/material/stepper';
import { MonthSelectComponent } from './month-select/month-select.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CustomMultiSelectComponent } from './custom-multi-select/custom-multi-select.component';
import { DuplicateRolesComponent } from './duplicate-roles/duplicate-roles.component';
import { ExcelUploadComponent } from './excel-upload/excel-upload.component';
import { CommonImportModalComponent } from './common-import-modal/common-import-modal.component';
import { CommonTimelineModalComponent } from './common-timeline-modal/common-timeline-modal.component'
@NgModule({
  declarations: [FileUploadComponent, BreadcrumbComponent, FilterComponent, FilterSortingComponent, FilterColumnComponent, FilterGroupComponent, LoaderComponent, SidemenuModalComponent, NotificationSettingModalComponent, CommonDocumentUploadComponent, YearDatePickerComponent, FilterParametersComponent, MonthSelectComponent, ResetPasswordComponent, CustomMultiSelectComponent, DuplicateRolesComponent, ExcelUploadComponent, CommonImportModalComponent, CommonTimelineModalComponent],
  imports: [SharedModule, ReactiveFormsModule,  MatExpansionModule, MatNativeDateModule,MatRadioModule,MatCheckboxModule,TranslateModule,MatIconModule,MatTableModule,MatPaginatorModule,MatSelectModule,Ng2SearchPipeModule,DragDropModule,NgxMatSelectSearchModule,MatInputModule,MatFormFieldModule,MonthDatePickerComponent,MatStepperModule],
  exports: [FileUploadComponent, BreadcrumbComponent, FilterComponent,LoaderComponent,SidemenuModalComponent, NotificationSettingModalComponent, CommonDocumentUploadComponent, YearDatePickerComponent,MonthDatePickerComponent, ResetPasswordComponent,CustomMultiSelectComponent,DuplicateRolesComponent,CommonImportModalComponent,CommonTimelineModalComponent],
})

export class ComponentsModule {}
