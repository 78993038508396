import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, ReplaySubject, of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { TokenData, User } from '../models/user';
import { environment } from 'src/environments/environment';
import jwt_decode from "jwt-decode";
import { KeycloakService } from './keycloak.service';
import { FLEET_ADMIN_ROLE, Toast } from 'src/app/shared/data/constants';
import { BaseDataService } from './base-data.service';
import { LookupImageModel } from 'src/app/home/master/driver-type-class-assign/models/driver-type-class-assign.model';
import { CompanySettingsService } from 'src/app/home/administration/company-settings/services/company-settings.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';

// import Keycloak, { KeycloakInstance } from 'keycloak-js';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  private loggedInSubject = new BehaviorSubject<boolean>(false);
  private currentCompanyChangedSubject = new BehaviorSubject<boolean>(false);
  public loggedIn = this.loggedInSubject.asObservable();

  public currentCompany: LookupImageModel;

  private authUrl = `${environment.authUrl}/realms/${environment.realm}/protocol/openid-connect/token`
  private apiContactUrl = 'api/Contact/';
  // user:User;
  private refreshToken: string;
  constructor(private http: HttpClient, private keyCloackService: KeycloakService, private baseDataService: BaseDataService, 
    private companySettingService: CompanySettingsService) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public setLoggedIn(value: boolean): void {
    this.loggedInSubject.next(value);
  }
  public setCurrentCompanyChangedSubject(value: boolean): void {
    this.currentCompanyChangedSubject.next(value);
  }

  public setCurrentCompany(currentCompany: LookupImageModel) {
    this.currentCompany = currentCompany

    localStorage.setItem("currentCompany", JSON.stringify(currentCompany))
  }
  public get getCurrentCompanyValue(): LookupImageModel {


    var el = localStorage.getItem("currentCompany") ?? null

    console.log(JSON.parse(el).id,'el');
    
    return el != "" ? JSON.parse(el) : null;
  }

  async doKeyCloackInIt(token_data: TokenData, token: string) {
    await this.keyCloackService.initializeKeyCloak();
    var kcAdmin = await this.keyCloackService.kcAdminClient;


    var role = await kcAdmin.clients.findRole({
      roleName: "Admin",
      realm: environment.realm,
      id: environment.clientDBId
    })


    var roleMappings = await kcAdmin.users.listRealmRoleMappings({

      id: token_data.sub,
      realm: environment.realm
    });
    // var rolePolicy = await kcAdmin.users.listRealmRoleMappings({
    //   id:roleMappings[0].id,
    //   realm:environment.realm
    // });


    const roleId = role?.id;

    // console.log(token_data);

    // var user=await kcAdmin.users.findOne({
    //   id:token_data.sub,
    //   realm:environment.realm
    // })

    // var userEntitlement=await kcAdmin.users.getEntitlement({

    // })
    // console.log("user",user);

  }
  login(username: string, password: string) {

    const myheader = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let body = new URLSearchParams();

    body.set("username", username)
    body.set("password", password)
    body.set("grant_type", "password")
    body.set("client_id", environment.clientId)
    body.set("client_secret", environment.clientSecret)

    return this.http
      .post<any>(this.authUrl, body.toString(), { headers: myheader })
      .pipe(
        map((res) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes

          var kcAdmin = this.keyCloackService.initializeKeyCloak()
          var token_data: TokenData = jwt_decode(res.access_token);

          this.doKeyCloackInIt(token_data, res.access_token)

          var roles = token_data?.realm_access?.roles ?? [];
          // var roles = [];
          // if (!roles.includes("All")) {
          //   roles.push("All")
          // }
          var user: User = {
            firstName: token_data.given_name,
            lastName: token_data.family_name,
            img: "",
            id: token_data.sub,
            password: "",
            role: roles,
            username: token_data.preferred_username,
            name: token_data.name,
            token: res.access_token,
            email: token_data.email,
            refresh_token: res?.refresh_token
          }


          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          this.setLoggedIn(true);
          // this.fetchUserCompanies()
          return user;
        })
      );
  }

  exchangeCodeForToken(code: string) {
    const myheader = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let body = new URLSearchParams();
    body.set("grant_type", "authorization_code")
    body.set("client_id", environment.clientId)
    body.set("client_secret", environment.clientSecret)
    body.set("redirect_uri", `${window.location.protocol}//${window.location.host}`)
    body.set("code", code)

    return this.http
    .post<any>(this.authUrl, body.toString(), { headers: myheader })
    .pipe(
      map((res) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes

   
   
        var token_data: TokenData = jwt_decode(res.access_token);

 console.log(token_data,"token_data");
 
 console.log(token_data,"token_data");
 

        var roles = token_data?.realm_access?.roles ?? [];
        var user: User = {
          firstName: token_data.given_name,
          lastName: token_data.family_name,
          img: "",
          id: token_data.sub,
          password: "",
          role: roles,
          username: token_data.preferred_username,
          name: token_data.name,
          token: res.access_token,
          email: token_data.email,
          refresh_token: res?.refresh_token
        }


        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        this.setLoggedIn(true);
        // this.fetchUserCompanies()
        return user;
      })
    );
  }
  setRefreshToken(refreshToken: string) {
    this.refreshToken = refreshToken;
  }

  getLogin() {
    this.loggedInSubject.subscribe((islogged) => {

      if (islogged) {
    

      }

    })
  }
  getRefreshToken(): Observable<any> {
    const body = new URLSearchParams();
    body.set('grant_type', 'refresh_token');
    body.set('client_id', environment.clientId);
    body.set('client_secret', environment.clientSecret);
    body.set('refresh_token', this.refreshToken);

    return this.http.post(this.authUrl, body.toString(), {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    });
  }
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('userContactData');
    localStorage.removeItem('currentCompany');
    localStorage.removeItem('userCompanies');
    this.currentUserSubject.next(null);

    this.setLoggedIn(false);
    return of({ success: false });
  }

  getPolicyRoles(userId: string,companyId:string=null) {
    var para="";

    if (companyId) {
      para="?companyId="+companyId
    }
    return this.baseDataService.makeGetCall('api/UserInformation/GetPolicyRolesByUserId/' + userId+para)
  }

  getContactByUserId(id: string) {
    return this.baseDataService.makeGetCall(`${this.apiContactUrl}getContactByUserIdAsync?userId=${id}`)
  }
  getUserCompanies(){
    return  JSON.parse(localStorage.getItem("userCompanies")) ?? []
  }

  getCurrentCompany(){
    return  JSON.parse(localStorage.getItem("currentCompany")) ?? null
  }
 fetchUserCompanies() : Observable<any>{
  var isFleetAdmin=this.currentUserValue.role
  
  return this.companySettingService.getuserCompanylookUp(isFleetAdmin.includes(FLEET_ADMIN_ROLE))
  
  }

}
