import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonImportSharedService {
  private subjectImportModal = new BehaviorSubject(null);

  constructor() { }

  setSubjectImportModal(str: string) {
    this.subjectImportModal.next(str);
  }
  getSubjectImportModal(): Observable<any> {
    return this.subjectImportModal.asObservable();
  }
}
