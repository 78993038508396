<form [formGroup]="multiSelectForm">
  <div class="row">
    <div class="col-md-12">
      <mat-form-field class="example-full-width form-input-field">
        <mat-label> {{inputLbl | translate}}</mat-label>
        <mat-select formControlName="SelectedValue" (selectionChange)="valueSelect($event)">
          <mat-option>
            <ngx-mat-select-search placeholderLabel="{{'MASTER_MENU.SEARCH' | translate}}" noEntriesFoundLabel="{{'MASTER_MENU.NO_RESULTS_FOUND' | translate}}" formControlName="SelectSearch"></ngx-mat-select-search>
          </mat-option>
          <mat-option class="form-select-option" *ngFor="let user of optionList | filter: multiSelectForm.get('SelectSearch').value" [value]="user">{{user.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-12">
      <div class="selected-item-container" [class]="isRequired ? 'reqed-input': '' ">
        <mat-label class="selected-item-container-lbl">&nbsp;{{ displayAreaLbl | translate}}</mat-label>
        <div class="w-100 Custom-chip-container" *ngIf="selectedValueList.length > 0">
          <div class="Custom-chip" *ngFor="let obj of selectedValueList; let itemInd = index">{{obj.name}}&nbsp;<div class="chip-close" (click)="removeItems(itemInd)"><mat-icon class="mat-icon-close">close</mat-icon></div></div>
        </div>
      </div>
      <mat-error class="multi-sec-error" *ngIf="isRequired && selectedValueList.length == 0"> {{'ADMINISTRATION_MENU.SELECTED_USERS_IS_REQUIRED' | translate}}</mat-error>
    </div>
  </div>
</form>
