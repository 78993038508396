import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VehicleSharedService {
  private vehicleFormSubject = new BehaviorSubject(null);
  private vehicleImageUrlSubject = new BehaviorSubject(null);
  private labelSubject = new BehaviorSubject(null);
  private subjectVehicleTableChanged = new BehaviorSubject(null);

  constructor() { }

  setSubjectVehicleTableChanged(flag: boolean) {
    this.subjectVehicleTableChanged.next(flag);
  }
  getSubjectVehicleTableChanged(): Observable<any> {
    return this.subjectVehicleTableChanged.asObservable();
  }

  setvehicleFormSubject(formGroup: FormGroup) {
    return this.vehicleFormSubject.next(formGroup);
  }

  getvehicleFormSubject(): Observable<FormGroup> {
    return this.vehicleFormSubject.asObservable();
  }
  setVehicleImageUrlubject(url:string) {
    return this.vehicleImageUrlSubject.next(url);
  }

  getVehicleImgUrlSubject(): Observable<string> {
    return this.vehicleImageUrlSubject.asObservable();
  }
  setLabelSubject(labels: string[]) {
    return this.labelSubject.next(labels);
  }
  getLabelSubject(): Observable<string[]> {
    return this.labelSubject.asObservable();
  }



}
