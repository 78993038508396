import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription, ReplaySubject, takeUntil, elementAt } from 'rxjs';
import { DuplicateRolesSharedService } from 'src/app/shared/services/duplicate-roles-shared.service';
import { DuplicateRolesService } from 'src/app/shared/services/duplicate-roles.service';
import { HelpersService } from '../../services/helpers.service';
import { ManageRolesService } from 'src/app/home/administration/manage-roles/services/manage-roles.service';
import { LookUpElement } from '../../models/common-data.model';
import { ActivatedRoute } from '@angular/router';
import { Toast } from '../../data/constants';
import { DuplicateRoles, ManageRoles } from 'src/app/home/administration/manage-roles/models/manage-roles.model';


@Component({
  selector: 'app-duplicate-roles',
  templateUrl: './duplicate-roles.component.html',
  styleUrls: ['./duplicate-roles.component.scss']
})
export class DuplicateRolesComponent {
  @ViewChild('template', { static: true }) template: any;
  duplicateRolesDataSubject: Subscription;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  modalRef: BsModalRef;
  duplicateRoleForm: FormGroup;
  isLoading:boolean = false;
  duplicateRolesReqBody: DuplicateRoles
  manageRolesLookup: LookUpElement[]
  role: ManageRoles;
  roleNameExists: boolean = false;
  initialRoleName: string = '';


  constructor(
    private duplicateRolesSharedService: DuplicateRolesSharedService,
    private duplicateRolesService: DuplicateRolesService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private translateService :TranslateService,
    private helpersService: HelpersService,
    private manageRolesService: ManageRolesService,
    private route: ActivatedRoute,
  ) {
    this.duplicateRolesDataSubject = duplicateRolesSharedService.getSubjectOpenDuplicateRolesModal()
    .pipe(takeUntil(this.destroy)).subscribe((res: any) => {
      if(res != null) {
        this.showModal();
        this.getRoleName(res);
      }
    })
  }

  ngOnInit(): void {
    this.createDuplicateRolesForm();
  }

  private showModal() {
    this.modalRef = this.modalService.show(this.template, {
      class: 'modal-dialog-centered modal-md',
    });
  }

  createDuplicateRolesForm() {
    this.duplicateRoleForm = this.fb.group({
      Id:[''],
      RoleName: ['', Validators.required],
    });
    this.duplicateRoleForm.get('RoleName').valueChanges
    .pipe(takeUntil(this.destroy))
    .subscribe(value => {
      this.checkIfRoleNameChanged(value);
    });
    
  }

  getRoleName(id: string) {
    this.manageRolesService.getPolicy(id).pipe(takeUntil(this.destroy)).subscribe((res: any) => {
      this.initialRoleName = res.policyName;
      this.roleNameExists = false; // Initially no changes have been made
      this.duplicateRoleForm.setValue({
        Id: res.id,
        RoleName: res.policyName
      });
    });
  }

  checkIfRoleNameChanged(currentValue: string) {
    if (this.duplicateRoleForm && this.duplicateRoleForm.get('RoleName')) {
      this.roleNameExists = (currentValue === this.initialRoleName);
    }
  }

  saveDuplicateRoles(formData: any) {
    this.isLoading = true;
    this.duplicateRolesReqBody = {
      id: formData?.Id,
      name: formData?.RoleName,
    }
    this.duplicateRolesService.duplicateUsers( this.duplicateRolesReqBody).pipe(takeUntil(this.destroy))
    .subscribe((res) => {
      Toast.fire({
        icon: 'success',
        title: this.translateService.instant('MASTER_MENU.UPDATED_SUCCESSFULLY'),
      });
      this.cancelForm();
      this.duplicateRolesSharedService.setSubjectManageRolesListChanged(true);
      this.isLoading = false;
    },(error) => {
      this.isLoading = false;
    })
  }

  cancelForm() {
    this.modalRef.hide();
    this.duplicateRoleForm.reset();
  }

  ngOnDestroy(): void {
    this.duplicateRolesSharedService.setSubjectOpenDuplicateRolesModal(null);
    this.modalRef?.hide();
    this.destroy.next(null);
  }

}
