import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PmsDataService {

  baseUrl: string;
  basePortalUrl: string;
  private token: string;
  private lang: string = "en"
  constructor(
    private http: HttpClient
  ) {
    this.baseUrl = environment.pmsBaseUrl;
    this.lang = localStorage.getItem('lang') ?? 'en'
    this.basePortalUrl=environment.portalBaseUrl
  }
  makeGetCall(path: string): Observable<any> {
    let headers = new HttpHeaders({ 'Authorization': 'bearer ' + this.getToken(), 'lang': this.lang });
    return this.http.get(this.constructUrl(path), { headers: headers })
  }


  makePostCall(path: string, body: any): Observable<any> {
    let bodyJson = JSON.stringify(body);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'bearer ' + this.getToken(), 'lang': this.lang });
    return this.http.post(this.constructUrl(path), bodyJson, { headers: headers })
  }


  makePostCallImageUpload(path: string, form: any): Observable<any> {
    return this.http.post(this.constructUrl(path), form)
  }

  makePostDownloadCall(path: string, body: any): Observable<any> {
    let bodyJson = JSON.stringify(body);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'bearer ' + this.getToken(), 'lang': this.lang });
    return this.http.post(this.constructUrl(path), bodyJson, { headers: headers, responseType: 'blob' });
  }
  makePutCall(path: string, body: any): Observable<any> {
    let bodyJson = JSON.stringify(body);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'bearer ' + this.getToken(), 'lang': this.lang });
    return this.http.put(this.constructUrl(path), bodyJson, { headers: headers })
  }
  makeDeleteCall(path: string): Observable<any> {
    let headers = new HttpHeaders({ 'Authorization': 'bearer ' + this.getToken(), 'lang': this.lang });
    return this.http.delete(this.constructUrl(path), { headers: headers })
  }


  makePortalPostCall(path: string, body: any): Observable<any> {
    let bodyJson = JSON.stringify(body);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'bearer ' + this.getToken(), 'lang': this.lang });
    return this.http.post(this.constructPortalUrl(path), bodyJson, { headers: headers })
  }

  private getToken() {
    return localStorage.getItem('token');
  }


  private constructUrl(path: string) {
    return this.baseUrl + path;
  }  
  private constructPortalUrl(path: string) {
    return this.basePortalUrl + path;
  }  
}
