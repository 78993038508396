import { Component, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { LookUpElement } from 'src/app/shared/models/common-data.model';
import { TranslateService } from '@ngx-translate/core';
import { CustomMultiSelectSharedService } from 'src/app/shared/services/custom-multi-select-shared.service';
import { Toast } from 'src/app/shared/data/constants';

@Component({
  selector: 'app-custom-multi-select',
  templateUrl: './custom-multi-select.component.html',
  styleUrls: ['./custom-multi-select.component.scss']
})
export class CustomMultiSelectComponent {
  @Input() optionList: LookUpElement[];
  @Input() inputLbl: string;
  @Input() displayAreaLbl: string;
  selectedValueList: any[] = [];
  multiSelectForm: FormGroup;
  valueArr: string[];
  multiValueChangeSubject: Subscription;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  @Input() isRequired: boolean;
  constructor(
    private fb: FormBuilder,
    private translateService :TranslateService,
    private customMultiSelectSharedService: CustomMultiSelectSharedService,
  ) {
    this.multiValueChangeSubject = customMultiSelectSharedService.getSubjectMultiSelectValue().pipe(takeUntil(this.destroy))
    .subscribe((data: any) => {
      if (data != null) {
        if(data.type == 'bind') {
          this.mapLookupValue(data?.list ?? []);
        }
      }
    });
  }

  ngOnInit(): void {
    this.createMultiSelectForm();
  }

  createMultiSelectForm() {
    this.multiSelectForm = this.fb.group({
      SelectedValue: [''],
      SelectSearch: ['']
    })
  }

  valueSelect(event: any) {
    if(this.valueArr?.includes(event.value.id)) {
      Toast.fire({
        icon: 'error',
        title: this.translateService.instant('MASTER_MENU.ALREADY_EXISTS'),
      })
      this.multiSelectForm.get('SelectedValue').setValue('')
    } else {
      this.selectedValueList.push(event.value)
      this.valueArr = this.selectedValueList.map((item) => { return item.id });
      let obj = {
        type: 'edit',
        list: this.valueArr
      }
      this.customMultiSelectSharedService.setSubjectMultiSelectValue(obj);
    }
  }

  mapLookupValue(data: any[]) {
    this.valueArr = data;
    this.valueArr.map((idVal)=>{
      if (this.optionList.length > 0) {
        this.optionList.map((option)=>{
          option.id
          if (option?.id == idVal) {
            this.selectedValueList.push(option);
          }
        })
      }
    });
  }

  removeItems ( itemInd: number) {
    this.selectedValueList.splice(itemInd, 1);
    this.valueArr = this.selectedValueList.map((item) => { return item.id });
    let obj = {
      type: 'edit',
      list: this.valueArr
    }
    this.customMultiSelectSharedService.setSubjectMultiSelectValue(obj);
  }

  ngOnDestroy(): void {
    this.customMultiSelectSharedService.setSubjectMultiSelectValue(null);
    this.destroy.next(null);
  }
}
