import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DuplicateRolesSharedService {

  private subjectOpenDuplicateRoleModal = new BehaviorSubject(null);
  private subjectManageRolesListTableChanged = new BehaviorSubject(null);

  constructor() { }

  setSubjectOpenDuplicateRolesModal(string: string) {
    this.subjectOpenDuplicateRoleModal.next(string)
  }

  getSubjectOpenDuplicateRolesModal() : Observable<any> {
    return this.subjectOpenDuplicateRoleModal.asObservable();
  }

  setSubjectManageRolesListChanged(flag:boolean) {
    this.subjectManageRolesListTableChanged.next(flag)
  }

  getSubjectManageRolesListChanged() : Observable<any> {
    return this.subjectManageRolesListTableChanged.asObservable();
  }
}
