import { Component } from '@angular/core';
import { Event, Router, NavigationStart, NavigationEnd } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { environment } from 'src/environments/environment';
import { DynamicScriptLoaderService } from './core/service/dynamic-script-loader.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  currentUrl: string;
  constructor(public _router: Router, location: PlatformLocation,private dynamicScriptLoaderService:DynamicScriptLoaderService) {
    if (environment.production) {
      
      window.console.log=function(){};
      // Override console.error to a function that does nothing
    window.console.error = function () {};

    // Override console.warn to a function that logs a warning message
    window.console.warn = function () {};
    
    }

    this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        // location.onPopState(() => {
        //   window.location.reload();
        // });
        this.currentUrl = routerEvent.url.substring(
          routerEvent.url.lastIndexOf('/') + 1
        );
      }
      if (routerEvent instanceof NavigationEnd) {
      }
      window.scrollTo(0, 0);
    });
    // this.loadGoogleMapsScript()
  }
  private loadGoogleMapsScript(): void {
    // const script = document.createElement('script');
    // script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}`;
    // script.async = true;
    // script.defer = true;
    // document.head.appendChild(script);
    this.dynamicScriptLoaderService.load('googleMaps').then(() => {
      console.log('Google Maps script loaded!');
      // You can now use Google Maps functionality here
    }).catch(error => console.error('Error loading Google Maps script:', error));
  
  }
 
}
