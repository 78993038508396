// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   baseUrl: 'https://fleetapi.codezync.net/',
//   authUrl:"http://localhost:8080",
//   clientId:"FleetClientApi",
//   clientSecret:"xY1qMBOyls0qbhdVIl8ZgcVvStkN5Q9l",
//   clientDBId:"ae095528-6176-4e48-842a-6f1652f52840",
//   realm:"Fleet"

// };

export const environment = { //demo servewr
  production: false,
  baseUrl: 'https://api.fleet.staging.codezync.net/',
  authUrl:"https://kcauth.codezync.net",
  clientId:"fleet-qa-client",
  clientSecret:"jrWhMpfChrlHtB7n8KWiN67DpTO6SfNI",
  signalRUrl:"https://api.fleet.staging.codezync.net/notificationHub",
  clientDBId:"a45668a5-c519-48df-8b26-36734cdc0024",
  realm:"fleet-qa-realm",
  // pmsBaseUrl:"https://ro-op-uat.gears-int.com/"
  pmsBaseUrl:"https://ro-op-uat.gears-int.com/api/v1/",
  // pmsBaseUrl:"https://pms-qa.gears-int.com/api/v1",
  portalBaseUrl:" https://ro-uat.gears-int.com/api/v1/",
  // portalBaseUrl:"https://pms-portal-qa.gears-int.com/api/v1/",
  googleMapsApiKey:"AIzaSyCpmo1d7ObB7rB82TWjP-dDyMMr1eW3CR4"

};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
