import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactsSharedService {

  constructor() { }

  private contactImageUrlSubject = new BehaviorSubject(null);
  private subjectContactTabChanged = new BehaviorSubject(null);
  private subjectContactTableChanged = new BehaviorSubject(null);

  setSubjectContactTableChanged(flag: boolean) {
    this.subjectContactTableChanged.next(flag);
  }
  getSubjectContactTableChanged(): Observable<any> {
    return this.subjectContactTableChanged.asObservable();
  }

  getContactImageUrl(): Observable<string>{
    return this.contactImageUrlSubject.asObservable()
  }

  setContactImageUrl(url:string){
    return this.contactImageUrlSubject.next(url);
  }

  setSubjectContactTabChanged(type :boolean){
    this.subjectContactTabChanged.next(type)
  }
  getSubjectContactTabChanged() :  Observable<boolean>{
   return this.subjectContactTabChanged.asObservable()
  }

}
