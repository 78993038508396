import { Component, ElementRef, HostListener, Input } from '@angular/core';

@Component({
  selector: 'app-excel-upload',
  templateUrl: './excel-upload.component.html',
  styleUrls: ['./excel-upload.component.scss']
})
export class ExcelUploadComponent {
  isBtnShow: boolean = false;
  onChange: Function;
  public file: File | null = null;

  @HostListener('change', ['$event.target.files'])

  emitFiles(event: FileList) {
    const file = event && event.item(0);
    this.file = file;
  }

  constructor(private host: ElementRef<HTMLInputElement>) {}

  writeValue(value: null) {
    // clear file input
    this.host.nativeElement.value = '';
    this.file = null;
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) {}
  btnTriggerClick() {
    let fileInput = document.getElementById('file-input');
    fileInput.click()
  }
}
