import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { datePikerModel } from '../models/common-data.model'

@Injectable({
  providedIn: 'root'
})
export class DatePickersSharedService {
  private subjectYearPickerValue = new BehaviorSubject(null);
  private subjectMonthPickerValue = new BehaviorSubject(null);

  constructor() { }

  setSubjectYearPickerValue(dateObj: datePikerModel) {
    this.subjectYearPickerValue.next(dateObj);
  }

  getSubjectYearPickerValue(): Observable<datePikerModel> {
    return this.subjectYearPickerValue.asObservable();
  }

  setSubjectMonthPickerValue(dateObj: datePikerModel) {
    this.subjectMonthPickerValue.next(dateObj);
  }

  getSubjectMonthPickerValue(): Observable<datePikerModel> {
    return this.subjectMonthPickerValue.asObservable();
  }
}
