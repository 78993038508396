<div class="file-drop-area">
  <div class="row">
    <div
      class="col-md-12 flex-fill file-input-container">
      <div class="row mt-3">
        <div class="col-md-12 text-center">
          <span>
          {{
            file
              ? file.name
              : ('VEHICLE_MENU.DRAG_AND_DROP_COPY_AND_PASTE_FILE_HERE'
                | translate)
          }}
          </span>
        <input class="file-input" id="file-input" placeholder="No File Selected" type="file" />
        </div>
        <div class="col-md-12 mt-2 text-center">
          {{"MASTER_MENU.OR" | translate}}
        </div>
        <div class="col-md-12 mt-2 text-center">
          <button mat-raised-button class="flat-info-btn" color="primary" (click)="btnTriggerClick()">
            {{"MASTER_MENU.BROWSE" | translate}}
          </button>
        </div>
      </div>

    </div>

  </div>
  <!-- <mat-hint>{{'COMMON.POSIBLE_FILE_TYPE_AND_SIZE' | translate}}</mat-hint> -->
  <!-- <mat-hint>Possible file types are <b>pdf</b>, <b>jpg</b> and <b>png</b>. Maxinum file size is <b>2MB</b></mat-hint> -->

</div>

