<div class="col-md-12">
    <form [formGroup]="monthSelectForm">
        <div class="col-md-4">
            <mat-form-field class="example-full-width form-input-field">
              <mat-label>{{'FUEL_HISTORY_MENU.VEHICLE_CODE_NAME' | translate}}</mat-label>
              <mat-select
                  formControlName="VehicleId"
              >
              <mat-option>
                <ngx-mat-select-search placeholderLabel="{{'MASTER_MENU.SEARCH' | translate}}" noEntriesFoundLabel="{{'MASTER_MENU.NO_RESULTS_FOUND' | translate}}"    [(ngModel)]="monthSearch" [ngModelOptions]="{standalone: true}">
                  ></ngx-mat-select-search>
              </mat-option>
              <mat-option class="form-select-option" *ngFor="let month of monthLookup | filter: monthSearch" [value]="month.id">{{month.name}}</mat-option>
              </mat-select>
          </mat-form-field>
          </div>
    </form>
</div>