import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/service/auth.service';
import { DirectionService } from 'src/app/core/service/direction.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: [],
})
export class MainLayoutComponent implements OnInit {
  direction: string;
  public config: any = {};
  constructor(private directoryService: DirectionService,private authService:AuthService,private router : Router) {
    this.directoryService.currentData.subscribe((currentData) => {
      if (currentData) {
        this.direction = currentData;
      } else {
        if (localStorage.getItem('isRtl')) {
          if (localStorage.getItem('isRtl') === 'true') {
            this.direction = 'rtl';
          } else if (localStorage.getItem('isRtl') === 'false') {
            this.direction = 'ltr';
          }
        } else {
          if (this.config?.layout?.rtl == true) {
            this.direction = 'rtl';
          } else {
            this.direction = 'ltr';
          }
        }
      }
    });

    // this.authService.loggedIn.subscribe((loggedIn: boolean) => {
    //   console.log(loggedIn,"loggedIn");
      
    //   if (!loggedIn) {
    //     // Perform sign-out actions
    //     // ...
    //     this.router.navigate(['/authentication/signin']);
    //   }
    // });


    window.addEventListener('storage', (event: StorageEvent) => {
      if (event.key === 'currentUser') {
        const currentUser = event.newValue;
        if (currentUser === null) {
          this.router.navigate(['/authentication/signin']);
          window.location.reload()
        } else {
          // Handle the case when 'currentUser' is not null
        }
      }
    });
  }
  ngOnInit(): void {
    
  }
}
