import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from 'src/app/core/service/base-data.service';
import { LookUpElement, VehicleBasicData } from '../models/common-data.model';

@Injectable({
  providedIn: 'root'
})
export class LookupsService {
  private apiPlateLookupUrl = 'api/Plate/lookUp';
  private apiVehiclePlateLookupUrl = 'api/VehiclePlate';
  private apiVehicleTypeLookupUrl = 'api/VehicleType/lookUp';
  private apiVehicleYearLookupUrl = 'api/VehicleYear/lookUp';
  private apiVehicleMakeLookupUrl = 'api/VehicleMake/lookUp';
  private apiVehicleModelByMakeLookupUrl = 'api/VehicleModel/lookUpByMake';
  private apiVehicleModelLookupUrl = 'api/VehicleModel/lookUp';
  private apiVehicleCategoryLookupUrl = 'api/VehicleCategory/lookUp';
  private apiVehicleStateProvinceLookupUrl = 'api/VehicleStateProvince/lookUp';
  private apiVehicleClassLookupUrl = 'api/VehicleClass/lookUp';
  private apiVehicleOwnershipLookupUrl = 'api/VehicleOwnership/lookUp';
  private apiVehicleColorLookupUrl = 'api/VehicleColor/lookUp';
  private apiVehicleBodyTypeLookupUrl = 'api/VehicleBodyType/lookUp';
  private apiTransmissionTypeLookupUrl = 'api/TransmissionType/lookUp';
  private apiFuelTypeLookupUrl = 'api/FuelType/lookUp';
  private apibrakeSystemLookupUrl = 'api/brakeSystem/lookUp';
  private apiDriverTypeLookupUrl = 'api/DriverType/lookUp';
  private apiDriverCategoryLookupUrl = 'api/DriverCategory/lookUp';
  private apiContactLookupUrl = 'api/Contact/lookUp';
  private apiDepartmentLookupUrl ='api/Department/lookUp';
  private apiVehicleLookupUrl ='api/Vehicle/lookUp';
  private apiVehicleUrl ='api/Vehicle';
  private apivehicleBasicDataUrl = 'api/Vehicle/getVehicleBasicInfo'
  private apiDriverCodeLookupUrl = 'api/Contact/getContactCodelookUp';
  private apiDriveTypeLookupUrl = 'api/DriveType/lookUp';
  private apiCompanySettingLookupUrl = 'api/CompanySetting/lookUp';
  private apiApprovalGroupLookupUrl = 'api/ApprovalGroup/lookUp';
  private apiSystemModuleLookupUrl = 'api/SystemModule/lookUp';
  private apiApprovalActionLookupUrl = 'api/ApprovalAction/lookUp';
  private apiVehicleAssignmentLookupUrl = 'api/VehicleAssignment/lookUp';
  private apiVehicleAssignmentInspectionLookupUrl = 'api/VehicleAssignment/lookUpForInspections';
  private apiVehicleAssignmentUrl ='api/VehicleAssignment';
  private apiCountryLookupUrl = 'api/Country/lookUp';
  private apiDepartmentByCompanyIdLookupUrl = 'api/Department/getDepartmentLookUpByCompanyId';
  private apiJobTitleLookupUrl = 'api/JobTitle/lookUp';
  private apiCodeLookupUrl ='api/CentralCode/lookUpByCodes';
  private apiCodeModuleLookupUrl ='api/CodeModule/lookUp';
  private apIncidentUrl = 'api/GeneralIncident';


  constructor(private baseDataService:BaseDataService) { }

  getPlatesLookup(): Observable<LookUpElement[]> {
   return this.baseDataService.makeGetCall(`${this.apiPlateLookupUrl}`)
  }
  getVehicleTypesLookup(): Observable<LookUpElement[]> {
   return this.baseDataService.makeGetCall(`${this.apiVehicleTypeLookupUrl}`)
  }
  getVehicleYearLookup(): Observable<LookUpElement[]> {
   return this.baseDataService.makeGetCall(`${this.apiVehicleYearLookupUrl}`)
  }
  getVehicleMakeLookup(): Observable<LookUpElement[]> {
   return this.baseDataService.makeGetCall(`${this.apiVehicleMakeLookupUrl}`)
  }
  getVehicleModelByMakeIdLookup(makeId:string): Observable<LookUpElement[]> {
   return this.baseDataService.makeGetCall(`${this.apiVehicleModelByMakeLookupUrl}?makeId=${makeId}`)
  }
  getVehicleModelLookup(): Observable<LookUpElement[]> {
   return this.baseDataService.makeGetCall(`${this.apiVehicleModelLookupUrl}`)
  }
  getVehicleVehicleStateProvinceLookup(): Observable<LookUpElement[]> {
   return this.baseDataService.makeGetCall(`${this.apiVehicleStateProvinceLookupUrl}`)
  }
  getVehicleCategoryLookup(): Observable<LookUpElement[]> {
   return this.baseDataService.makeGetCall(`${this.apiVehicleCategoryLookupUrl}`)
  }
  getVehicleClassLookup(): Observable<LookUpElement[]> {
   return this.baseDataService.makeGetCall(`${this.apiVehicleClassLookupUrl}`)
  }
  getVehicleOwnershipLookup(): Observable<LookUpElement[]> {
   return this.baseDataService.makeGetCall(`${this.apiVehicleOwnershipLookupUrl}`)
  }
  getVehicleColorLookup(): Observable<LookUpElement[]> {
   return this.baseDataService.makeGetCall(`${this.apiVehicleColorLookupUrl}`)
  }
  getVehicleBodyTypeLookup(): Observable<LookUpElement[]> {
   return this.baseDataService.makeGetCall(`${this.apiVehicleBodyTypeLookupUrl}`)
  }
  getTransmissionTypeLookup(): Observable<LookUpElement[]> {
   return this.baseDataService.makeGetCall(`${this.apiTransmissionTypeLookupUrl}`)
  }
  getapiFuelTypeLookup(): Observable<LookUpElement[]> {
   return this.baseDataService.makeGetCall(`${this.apiFuelTypeLookupUrl}`)
  }
  getapiBreakSystemLookup(): Observable<LookUpElement[]> {
   return this.baseDataService.makeGetCall(`${this.apibrakeSystemLookupUrl}`)
  }
  getapiDriverTypeLookup(): Observable<LookUpElement[]> {
   return this.baseDataService.makeGetCall(`${this.apiDriverTypeLookupUrl}`)
  }
  getapiDriverCategoryLookup(): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(`${this.apiDriverCategoryLookupUrl}`)
  }
  getapiContactLookup(type:string = 'BOTH'): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(`${this.apiContactLookupUrl}?type=${type}`)
  }
  getapiDriverCodeLookup(): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(`${this.apiDriverCodeLookupUrl}`)
  }
  getapiDepartmentLookup(isFetchAll: boolean = false): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(`${this.apiDepartmentLookupUrl}?isFetchAll=${isFetchAll}`, )
  }
  getapiVehicleLookup(): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(`${this.apiVehicleLookupUrl}`)
  }
  getVehicleBasicData(id: string): Observable<VehicleBasicData> {
    let queryString = `vehicleId=${id}`;
    return this.baseDataService.makeGetCall(`${this.apivehicleBasicDataUrl}?${queryString}`)
  }
  getapiDriveTypeLookup(): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(`${this.apiDriveTypeLookupUrl}`)
  }
  getapiCompanySettingLookup(): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(`${this.apiCompanySettingLookupUrl}`)
  }
  getapiApprovalGroupLookup(): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(`${this.apiApprovalGroupLookupUrl}`)
  }
  getapiSystemModuleLookup(): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(`${this.apiSystemModuleLookupUrl}`)
  }
  getapiApprovalActionLookup(): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(`${this.apiApprovalActionLookupUrl}`)
  }
  getapiVehicleAssignmentLookup(isAll: boolean = false): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(`${this.apiVehicleAssignmentLookupUrl}?isAllData=${isAll}`)
  }
  getapiCountryLookup(): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(`${this.apiCountryLookupUrl}`)
  }
  getDepartmentLookupByCompanyId(id: string): Observable<LookUpElement[]> {
    let queryString = `companySettingId=${id}`;
    return this.baseDataService.makeGetCall(`${this.apiDepartmentByCompanyIdLookupUrl}?${queryString}`)
  }
  getVehiclePlateByVehicleIdLookup(vehicleId:string): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(`${this.apiVehiclePlateLookupUrl}/getVehiclePlateLookUpByVehicleIdAsync?vehicleId=${vehicleId}`)
   }
  getVehicleListByPlateIdLookup(plateId: string): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(`${this.apiVehicleUrl}/getVehiclesByPlateId?plateId=${plateId}`)
   }
   getJobTitleLookup(): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(`${this.apiJobTitleLookupUrl}`)
   }
   getCodeLookup(): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(`${this.apiJobTitleLookupUrl}`)
   }
   getapiContactLookupByDepartmentId(type, departmentId): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(`${this.apiContactLookupUrl}?type=${type ?? 'BOTH'}&departmentId=${departmentId}`)
  }
  getCodeModuleLookup(): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(`${this.apiCodeModuleLookupUrl}`)
  }
  getapiVehicleAssignmentInspectionLookup(): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(`${this.apiVehicleAssignmentInspectionLookupUrl}`)
  }
  getApiVehicleAssignmentExpenseHistory(): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(`${this.apiVehicleAssignmentUrl}/getExpensesHistoryVehicleAssignmentLookUpAsync`)
  }
  getOperatorsLookupByVehicleAssignmentId(assignmentId: string): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(`${this.apIncidentUrl}/operatorLookUp?assignmentId=${assignmentId}`)
  }
  getapiCodeLookup(centralCode: any): Observable<any> {
    return this.baseDataService.makePostCall(`${this.apiCodeLookupUrl}`, centralCode)
  }
}
