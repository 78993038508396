import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationSettingSharedService {

  constructor() { }

  private subjectOpenNotificationSettingModal = new BehaviorSubject(null);
  private subjectCompanyListChanged = new BehaviorSubject(null);


  setSubjectOpenNotificationSettingModal(string: string) {
    this.subjectOpenNotificationSettingModal.next(string);
  }

  getSubjectNotificationSettingModal(): Observable<any> {
    return this.subjectOpenNotificationSettingModal.asObservable();
  }

  getSubjectCompanyListChanged(): Observable<any> {
    return this.subjectCompanyListChanged.asObservable();
  }

  setSubjectCompanyListChanged(flag: boolean) {
    this.subjectCompanyListChanged.next(flag);
  }
}
