<mat-form-field class="example-full-width form-input-field">
    <mat-label>{{'MASTER_MENU.MONTH_AND_YEAR' | translate}}</mat-label>
    <input matInput [max]="maxDate" [matDatepicker]="dp" [formControl]="date" readonly>
    <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker #dp
                    startView="multi-year"
                    (monthSelected)="setMonthAndYear($event, dp)"
                    panelClass="example-month-picker">
    </mat-datepicker>
  </mat-form-field>
