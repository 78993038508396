import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from 'src/app/core/service/base-data.service';
import { DuplicateRoles } from 'src/app/home/administration/manage-roles/models/manage-roles.model';

@Injectable({
  providedIn: 'root'
})
export class DuplicateRolesService {

  private apiDuplicatePolicyUserSync = 'api/UserPolicy/duplicateUserPolicyAsync'

  constructor(private baseDataService: BaseDataService) { }

  duplicateUsers(duplicateRole:DuplicateRoles): Observable<any>{
    return this.baseDataService.makePutCall(`${this.apiDuplicatePolicyUserSync}`,duplicateRole)
  }
}
