import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/service/auth.service';
import { KeycloakService } from 'src/app/core/service/keycloak.service';
import { LanguageService } from 'src/app/core/service/language.service';
import { LookupImageModel } from 'src/app/home/master/driver-type-class-assign/models/driver-type-class-assign.model';
import {
  FLEET_ADMIN_ROLE,
  PSA_ROLE,
  Toast,
} from 'src/app/shared/data/constants';

@Component({
  selector: 'app-sso-callback',
  templateUrl: './sso-callback.component.html',
  styleUrls: ['./sso-callback.component.scss'],
})
export class SsoCallbackComponent {
  /**
   *
   */
  loading: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private languageService: LanguageService,
    private keycloackService: KeycloakService
  ) {}

  ngOnInit(): void {
    // this.route.queryParams.subscribe((params) => {
    //   const code = params['code'];
    //   const state = params['state'];

    //   if (code) {
    //     this.exchangeCodeForToken(code);
    //   } else {
    //     // Handle error
    //   }
    // });
    const fragment = window.location.hash.substr(1);
    console.log(fragment, 'fragment');

    // Parse the fragment to extract the query parameters
    const urlParams = new URLSearchParams(fragment.split('?')[1]);

    const code = urlParams.get('code');
    const state = urlParams.get('state');
    const error = urlParams.get('error');

    if (code) {
      // Exchange the authorization code for a token
      this.exchangeCodeForToken(code);
    } else if (error) {
      // Handle error
      console.error('Error during SSO:', error);
      Toast.fire({
        icon: 'error',
        title: 'Invalid login please try gain',
      });
      this.router.navigate(['/authentication/signin']);
    }
  }
  // exchangeCodeForToken(code: string) {
  //   this.authService.exchangeCodeForToken(code).subscribe((res) => {
  //     this.loading = true;
  //     this.authService.fetchUserCompanies().subscribe(
  //       (resCompany: LookupImageModel[]) => {
  //         var isFleetAdmin = this.authService.currentUserValue.role;
  //         localStorage.setItem('userCompanies', JSON.stringify(resCompany));
  //         if (resCompany.length > 0) {
  //           var defaultCompany =
  //             resCompany.find((x) => x.isDefault) ?? resCompany[0];
  //             this.authService.setCurrentCompany(defaultCompany);

  //           if (!res.role?.includes(FLEET_ADMIN_ROLE)) {
  //             this.authService
  //               .getContactByUserId(res.id)
  //               .subscribe((contactRes) => {
  //                 localStorage.setItem(
  //                   'userContactData',
  //                   JSON.stringify(contactRes)
  //                 );
  //               },(err)=>{
  //                 Toast.fire({
  //                   icon: 'error',
  //                   title: 'Invalid login please try gain',
  //                 });
  //                 this.router.navigate(['/authentication/signin']);
  //               });
  //           }
  //           setTimeout(() => {
  //             const role = this.authService.currentUserValue.role;
  //             if (res.role?.includes(FLEET_ADMIN_ROLE)) {
  //               this.router.navigate(['/dashboard']);
  //               this.loading = false;
  //               setTimeout(() => {
  //                 window.location.reload();
  //               }, 1000);
  //             } else {
  //               this.authService
  //                 .getPolicyRoles(
  //                   res.id,
  //                   this.authService.getCurrentCompany()?.id ?? null
  //                 )
  //                 .subscribe((rolesRes) => {
  //                   res.role = rolesRes;
  //                   localStorage.setItem('currentUser', JSON.stringify(res));
  //                   this.authService.currentUserSubject.next(res);

  //                   this.router.navigate(['/dashboard']);
  //                   this.loading = false;
  //                   setTimeout(() => {
  //                     window.location.reload();
  //                   }, 1000);
  //                 },(err)=>{
  //                   Toast.fire({
  //                     icon: 'error',
  //                     title: 'Invalid login please try gain',
  //                   });
  //                   this.router.navigate(['/authentication/signin']);
  //                 });
  //             }
  //           }, 3000);
  //         } else {
  //           if (
  //             !isFleetAdmin.includes(FLEET_ADMIN_ROLE) &&
  //             !isFleetAdmin.includes(PSA_ROLE)
  //           ) {
  //             Toast.fire({
  //               icon: 'error',
  //               title:
  //                 'You are not assigned to a company. Please contact an administrator.',
  //             });
  //             this.authService.logout();
  //             window.location.reload();
  //           }
  //         }
  //       },
  //       (error) => {
  //         Toast.fire({
  //           icon: 'error',
  //           title: 'Invalid login please try gain',
  //         });
  //         this.authService.logout();
  //         // window.location.reload();
  //         this.loading = false;
  //       }
  //     );
  //   });
  // }
  exchangeCodeForToken(code: string) {
    this.loading = true;
  
    this.authService.exchangeCodeForToken(code).subscribe({
      next: (res) => {
        this.authService.fetchUserCompanies().subscribe({
          next: (resCompany: LookupImageModel[]) => {
            this.handleUserCompanies(res, resCompany);
          },
          error: () => this.handleLoginError('Invalid login please try again'),
        });
      },
      error: () => this.handleLoginError('Invalid login please try again'),
    });
  }
  
  private handleUserCompanies(res: any, resCompany: LookupImageModel[]) {
    localStorage.setItem('userCompanies', JSON.stringify(resCompany));
    
    if (resCompany.length === 0) {
      this.handleNoCompanies(res);
      return;
    }
  
    const defaultCompany = resCompany.find((x) => x.isDefault) ?? resCompany[0];
    this.authService.setCurrentCompany(defaultCompany);
  
    if (!res.role?.includes(FLEET_ADMIN_ROLE)) {
      this.authService.getContactByUserId(res.id).subscribe({
        next: (contactRes) => {
          localStorage.setItem('userContactData', JSON.stringify(contactRes));
          this.navigateToDashboard(res);
        },
        error: () => this.handleLoginError('Invalid login please try again'),
      });
    } else {
      this.navigateToDashboard(res);
    }
  }
  
  private navigateToDashboard(res: any) {
    setTimeout(() => {
      const role = this.authService.currentUserValue.role;
      
      if (res.role?.includes(FLEET_ADMIN_ROLE)) {
        this.completeLogin(res);
      } else {
        this.authService.getPolicyRoles(res.id, this.authService.getCurrentCompany()?.id ?? null).subscribe({
          next: (rolesRes) => {
            res.role = rolesRes;
            localStorage.setItem('currentUser', JSON.stringify(res));
            this.authService.currentUserSubject.next(res);
            this.completeLogin(res);
          },
          error: () => this.handleLoginError('Invalid login please try again'),
        });
      }
    }, 3000);
  }
  
  private completeLogin(res: any) {
    this.router.navigate(['/dashboard']);
    this.loading = false;
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
  
  private handleNoCompanies(res: any) {
    const isFleetAdmin = this.authService.currentUserValue.role;
    
    if (!isFleetAdmin.includes(FLEET_ADMIN_ROLE) && !isFleetAdmin.includes(PSA_ROLE)) {
      Toast.fire({
        icon: 'error',
        title: 'You are not assigned to a company. Please contact an administrator.',
      });
      this.authService.logout();
      window.location.reload();
    }
  }
  
  private handleLoginError(message: string) {
    Toast.fire({
      icon: 'error',
      title: message,
    });
    this.authService.logout();
    this.loading = false;
    this.router.navigate(['/authentication/signin']);
  }
  
}
