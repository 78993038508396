import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { DataTableColumn } from 'src/app/shared/models/data-table.model';
import { FilterData } from 'src/app/shared/models/filter-data.model';
import { NotificationSettingSharedService } from '../../services/notification-setting-shared.service';
// import { PlateService } from '../../services/plate.service';
// import { Plate } from '../../models/plate.model';
import { Toast } from 'src/app/shared/data/constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-notification-setting-modal',
  templateUrl: './notification-setting-modal.component.html',
  styleUrls: ['./notification-setting-modal.component.sass']
})
export class NotificationSettingModalComponent {
  @ViewChild('template', { static: true }) template: any;

  plateAddDataSubject: Subscription;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  modalRef: BsModalRef;
  plateForm: FormGroup;

  constructor(
    private notificationSettingSharedService: NotificationSettingSharedService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private translateService :TranslateService,
  ) {
    this.plateAddDataSubject = notificationSettingSharedService
      .getSubjectNotificationSettingModal()
      .pipe(takeUntil(this.destroy))
      .subscribe((sortByOpen: any) => {
        if (sortByOpen != null && sortByOpen == 'open') {
          this.showModal();
        }
      });
  }
  ngOnDestroy(): void {
    this.plateForm.reset();
    this.notificationSettingSharedService.setSubjectOpenNotificationSettingModal(null);
    this.modalRef?.hide();
    this.destroy.next(null);
  }

  ngOnInit(): void {
    this.createAddPlateForm();
  }

  private showModal() {
    this.modalRef = this.modalService.show(this.template, {
      class: 'modal-dialog-right',
    });
  }

  createAddPlateForm() {
    this.plateForm = this.fb.group({
      plateNumber: ['', Validators.required],
      secondaryName: ['',Validators.required],
      isActive: [true],
    });
  }

  cancelForm() {
    this.modalRef?.hide();
  }
}
