import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RequestSharedService {

  constructor() {}

  private subjectOpenApprovalHistoryLevelModal = new BehaviorSubject(null)
  private subjectRequestListChanged = new BehaviorSubject(null)
  private subjectOpenRecurringRequestModal = new BehaviorSubject(null);
  private subjectGenerateRequestSchedule = new BehaviorSubject(null);

  setSubjectOpenRecurringRequestModal(id: string) {
    this.subjectOpenRecurringRequestModal.next(id);
  }

  getSubjectOpenRecurringRequestModal():Observable<any> {
    return this.subjectOpenRecurringRequestModal.asObservable();
  }

  setSubjectGenerateRequestSchedule(value: any) {
    this.subjectGenerateRequestSchedule.next(value);
  }

  setSubjectOpenWorkflowRequestModal(id: any) {
    this.subjectOpenApprovalHistoryLevelModal.next(id);
  }

  getSubjectOpenWorkflowRequestModal(): Observable<any> {
    return this.subjectOpenApprovalHistoryLevelModal.asObservable();
  }

  setSubjectRequestListChanged(flag:boolean){
    this.subjectRequestListChanged.next(flag);
  }

  getSubjectRequestListChanged(): Observable<any> {
    return this.subjectRequestListChanged.asObservable();
  }

  setsubjectGenerateRequestSchedule(value: any) {
    this.subjectGenerateRequestSchedule.next(value);
  }

  getSubjectGenerateRequestSchedule():Observable<any> {
    return this.subjectGenerateRequestSchedule.asObservable();
  }

}
