import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, Renderer2, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ReplaySubject, takeUntil } from 'rxjs';
import { PMSResponseData } from 'src/app/core/models/config.interface';
import { PmsDataService } from 'src/app/core/service/pms-data.service';
import { SidebarSharedService } from 'src/app/layout/sidebar/services/sidebar-shared.service';
import { RouteInfo, RouteInfoSide } from 'src/app/layout/sidebar/sidebar.metadata';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sidemenu-modal',
  templateUrl: './sidemenu-modal.component.html',
  styleUrls: ['./sidemenu-modal.component.scss']
})
export class SidemenuModalComponent {
  @ViewChild('template', { static: true }) template: any;
  modalRef: BsModalRef;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  navWidth: string;
  routeInfoSide: RouteInfoSide;
  public sidebarItems: RouteInfo[] = [];

  /**
   *
   */
  constructor(
    private modalService: BsModalService,
    private sidebarSharedService: SidebarSharedService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private pmsService: PmsDataService
  ) {
    sidebarSharedService.getsubjectSideMenuOpen().pipe(takeUntil(this.destroy))
      .subscribe((sortByOpen: any) => {
        this.closeNav()
        if (sortByOpen != null && sortByOpen == 'open') {
          this.openNav()

        } else {
          this.closeNav()
        }
      });
    sidebarSharedService.getsubjectSideMenuData().pipe(takeUntil(this.destroy))
      .subscribe((routeList: RouteInfoSide) => {
        if (routeList != null) {

          this.routeInfoSide = routeList;
          this.sidebarItems = routeList.submenu
          // this.showModal()
        }
      });
  }
  openNav() {
    this.navWidth = "378px"
  }
  closeNav() {
    this.navWidth = "0";

  }


  callToggleMenu(event: any, length: any) {
    if (length > 0) {
      const parentElement = event.target.closest('li');
      const child = parentElement.querySelector('.child-menu')

      console.log(child);

      const activeClass = child.classList.contains('active');



      if (activeClass) {
        this.renderer.removeClass(child, 'active');
      } else {
        this.renderer.addClass(child, 'active');
      }
    } else {
      this.closeNav()
    }
  }
  adminPanel() {



    this.pmsService.makePortalPostCall('get_login_url', {}).pipe().subscribe((res: PMSResponseData) => {
      // Swal.showLoading()
      if (res.success) {

        // window.location.href = res.data.url;
        window.open(res.data.url, '_blank')
        // Swal.hideLoading()

      }



    })


  }
  callExternal(type) {

    if (type == "PORTAL") {
      this.adminPanel()

    }
  }
}
